import React, { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import { UploadFile } from "@mui/icons-material";
import PdfViewer from "../components/PdfViewer";
import CustomChatInterface from "../components/CustomChatInterface";
import UploadWarningDialog from "../components/UploadWarningDialog";
import AdaptiveChatContainer from "../components/AdaptiveChatContainer";
import SaveFileModal from "./SaveFileModal";

import {
  uploadSingleDocument,
  uploadMultipleDocuments,
  queryDocuments,
} from "../api/apiCalls";

const TakeNotesTab = ({
  documents,
  setDocuments,
  selectedDocument,
  setSelectedDocument,
  documentViewerUrls,
  setDocumentViewerUrls,
  scale,
  setScale,
  pageNumber,
  setPageNumber,
  numPages,
  setNumPages,
  messages,
  setMessages,
  isTyping,
  setIsTyping,
  error,
  setError,
  isError,
  setIsError,
  setTokenInfo,
}) => {
  const [stagedFiles, setStagedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fileDataRef = useRef(new Map());
  const [showUploadWarning, setShowUploadWarning] = useState(false);
  const [pendingUploadAction, setPendingUploadAction] = useState(null);
  const [saveChatModalOpen, setSaveChatModalOpen] = useState(false);
  const [chatFilename, setChatFilename] = useState("");

  // Effect for scrolling to chat interface on first message
  useEffect(() => {
    if (messages.length === 1) {
      setTimeout(() => {
        document.getElementById("chat-interface-container")?.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }, 100);
    }
  }, [messages]);

  // Effect for blob URL management
  useEffect(() => {
    // Restore blob URLs for existing documents
    documents.forEach((doc) => {
      if (
        !documentViewerUrls[doc.filename] &&
        fileDataRef.current.has(doc.filename)
      ) {
        const file = fileDataRef.current.get(doc.filename);
        const blobUrl = URL.createObjectURL(file);
        setDocumentViewerUrls((prev) => ({
          ...prev,
          [doc.filename]: blobUrl,
        }));
      }
    });

    // Cleanup function
    return () => {
      Object.entries(documentViewerUrls).forEach(([filename, url]) => {
        if (!documents.some((doc) => doc.filename === filename)) {
          URL.revokeObjectURL(url);
        }
      });
    };
  }, [documents, documentViewerUrls, setDocumentViewerUrls]);

  const handleFileSelection = (event) => {
    const files = Array.from(event.target.files);
    const MAX_FILE_SIZE = 25 * 1024 * 1024; // 25MB

    const validFiles = files.filter((file) => {
      if (file.size > MAX_FILE_SIZE) {
        setError(`${file.name} exceeds maximum file size of 25MB`);
        setIsError(true);
        return false;
      }
      if (file.type !== "application/pdf") {
        setError(`${file.name} is not a PDF file`);
        setIsError(true);
        return false;
      }
      return true;
    });

    if (validFiles.length > 4) {
      setError("You can upload a maximum of 4 files at once.");
      setIsError(true);
      return;
    }

    const totalSize = validFiles.reduce((sum, file) => sum + file.size, 0);
    if (totalSize > 100 * 1024 * 1024) {
      setError("The total size of all files must not exceed 100MB.");
      setIsError(true);
      return;
    }

    setStagedFiles((prev) => [...prev, ...validFiles]);
  };

  const handleUpload = async () => {
    if (messages.length > 0) {
      setPendingUploadAction(() => async () => {
        await performUpload();
      });
      setShowUploadWarning(true);
      return;
    }

    await performUpload();
  };

  const performUpload = async () => {
    setError("");
    setIsError(false);
    setIsLoading(true);

    try {
      let uploadedDocs;
      setMessages([]);

      if (stagedFiles.length === 1) {
        const response = await uploadSingleDocument(stagedFiles[0]);
        const blobUrl = URL.createObjectURL(stagedFiles[0]);
        fileDataRef.current.set(response.filename, stagedFiles[0]);
        setDocumentViewerUrls((prev) => ({
          ...prev,
          [response.filename]: blobUrl,
        }));
        uploadedDocs = [response];
        setDocuments(uploadedDocs);
        setSelectedDocument(response);
      } else if (stagedFiles.length > 1) {
        const response = await uploadMultipleDocuments(stagedFiles);
        response.forEach((doc, index) => {
          const blobUrl = URL.createObjectURL(stagedFiles[index]);
          fileDataRef.current.set(doc.filename, stagedFiles[index]);
          setDocumentViewerUrls((prev) => ({
            ...prev,
            [doc.filename]: blobUrl,
          }));
        });
        uploadedDocs = response;
        setDocuments(response);
        setSelectedDocument(response[0]);
      }

      setStagedFiles([]);

      // Initial query after upload
      const queryRequest = {
        file_info: uploadedDocs,
        query:
          "Please identify the provided text (author, title, publication info), analyze, and give a summary of key points. Then ask if user wants further discussion. Dispense with any metacommentary on what you are tasked to do.",
        llm_choice: "gemini-2.0-flash-exp",
      };

      const response = await queryDocuments(queryRequest);
      setMessages([
        {
          message: response.response,
          sender: "MakGPT",
          sentTime: new Date().toLocaleString(),
        },
      ]);
    } catch (error) {
      console.error("Upload error:", error);
      setError(error.message || "Error uploading files");
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveAndProceed = async () => {
    setSaveChatModalOpen(true);
    setPendingUploadAction(() => async () => {
      setShowUploadWarning(false);
      if (pendingUploadAction) {
        await pendingUploadAction();
        setPendingUploadAction(null);
      }
    });
  };

  const handleProceedWithoutSaving = async () => {
    setShowUploadWarning(false);
    if (pendingUploadAction) {
      await pendingUploadAction();
      setPendingUploadAction(null);
    }
  };

  const handleSendMessage = async (message) => {
    if (!documents.length) {
      setError("Please upload at least one document first.");
      setIsError(true);
      return;
    }

    const userMessage = {
      message,
      sender: "You",
      sentTime: new Date().toLocaleString(),
    };
    setMessages((prev) => [...prev, userMessage]);

    setIsTyping(true);
    try {
      const queryRequest = {
        file_info: documents,
        query: message,
        llm_choice: "gemini-2.0-flash-exp",
        chat_history: messages.map((msg) => ({
          role: msg.sender === "User" ? "user" : "model",
          parts: msg.message,
        })),
      };

      const response = await queryDocuments(queryRequest);
      const systemMessage = {
        message: response.response,
        sender: "MakGPT",
        sentTime: new Date().toLocaleString(),
      };
      setMessages((prev) => [...prev, systemMessage]);

      if (response.token_count) {
        setTokenInfo(response.token_count);
      }
    } catch (error) {
      console.error("Query error:", error);
      setError("Error processing query: " + (error.message || "Unknown error"));
      setIsError(true);
      setMessages((prev) => prev.slice(0, -1));
    } finally {
      setIsTyping(false);
    }
  };

  const handleDocumentSelect = (document) => {
    setSelectedDocument(document);
    setPageNumber(1);
  };

  useEffect(() => {
    if (saveChatModalOpen) {
      const generateDefaultFilename = () => {
        const timestamp = new Date()
          .toISOString()
          .replace(/[:]/g, "-")
          .split(".")[0];
        const baseFilename = selectedDocument?.filename
          ? selectedDocument.filename
              .replace(/\.pdf$/i, "")
              .replace(/[^a-zA-Z0-9-_]/g, "_")
              .substring(0, 50)
          : "chat-export";
        return `${baseFilename}_${timestamp}`;
      };
      setChatFilename(generateDefaultFilename());
    }
  }, [saveChatModalOpen, selectedDocument]);

  const handleSaveChat = () => {
    const generateDefaultFilename = () => {
      const timestamp = new Date()
        .toISOString()
        .replace(/[:]/g, "-")
        .split(".")[0];
      const baseFilename = selectedDocument?.filename
        ? selectedDocument.filename
            .replace(/\.pdf$/i, "")
            .replace(/[^a-zA-Z0-9-_]/g, "_")
            .substring(0, 50)
        : "chat-export";
      return `${baseFilename}_${timestamp}`;
    };

    const chatContent = messages
      .map((msg) => `[${msg.sentTime}] ${msg.sender}: ${msg.message}`)
      .join("\n");

    const element = document.createElement("a");
    const file = new Blob([chatContent], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `${chatFilename || generateDefaultFilename()}.txt`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    URL.revokeObjectURL(element.href);
    setSaveChatModalOpen(false);
    setChatFilename("");

    if (pendingUploadAction) {
      pendingUploadAction();
      setPendingUploadAction(null);
    }
  };

  const zoomIn = () => setScale((scale) => scale * 1.2);
  const zoomOut = () => setScale((scale) => Math.max(scale * 0.8, 0.5));

  return (
    <Box
      sx={{
        flexGrow: 1,
        minHeight: "100%",
        bgcolor: "#282c34",
        pb: { xs: 4, md: 0 },
      }}
    >
      {isError && (
        <Alert
          severity="error"
          onClose={() => setIsError(false)}
          sx={{ position: "fixed", top: 16, right: 16, zIndex: 1000 }}
        >
          {error}
        </Alert>
      )}

      <UploadWarningDialog
        isOpen={showUploadWarning}
        onClose={() => {
          setShowUploadWarning(false);
          setPendingUploadAction(null);
        }}
        onProceed={handleProceedWithoutSaving}
        onSaveChat={handleSaveAndProceed}
      />

      <SaveFileModal
        open={saveChatModalOpen}
        onClose={() => {
          setSaveChatModalOpen(false);
          setChatFilename("");
        }}
        onSave={handleSaveChat}
        title="Save Chat History"
        filename={chatFilename}
        onFilenameChange={(e) => setChatFilename(e.target.value)}
        placeholder="chat_history"
        fileExtension=".txt"
      />

      <Box
        className="pdf-takenotes-container"
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          height: { md: "100vh" },
          overflow: { md: "hidden" },
          gap: 2,
        }}
      >
        {/* Left Column - PDF Section */}
        <Box
          className="pdf-takenotes-tab-left"
          sx={{
            flex: 1,
            overflowY: { md: "auto" },
            height: { md: "100%" },
            p: 2,
            width: { xs: "90%", md: "50%" },  // Add this line to control width
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              bgcolor: "#444",
            },
            "&::-webkit-scrollbar-thumb": {
              bgcolor: "#666",
              borderRadius: "4px",
              "&:hover": {
                bgcolor: "#888",
              },
            },
          }}
        >
          {/* 
            If you want to show a simple "Uploading..." UI while isLoading is true,
            you could do something like this (optional):
          */}
          {isLoading && (
            <Box sx={{ width: "100%", mb: 2 }}>
              <Paper
                elevation={0}
                sx={{
                  p: 2,
                  bgcolor: "#2c3036",
                  borderRadius: 1,
                  border: "1px solid",
                  borderColor: "divider",
                }}
              >
                <Typography variant="body2" sx={{ color: "#888", mb: 1 }}>
                  {stagedFiles.length > 0
                    ? `Uploading ${stagedFiles.length} document${
                        stagedFiles.length > 1 ? "s" : ""
                      }...`
                    : "Processing..."}
                </Typography>
                <LinearProgress
                  sx={{
                    height: 4,
                    borderRadius: 2,
                    bgcolor: "rgba(255, 255, 255, 0.08)",
                    ".MuiLinearProgress-bar": {
                      bgcolor: "primary.main",
                    },
                  }}
                />
              </Paper>
            </Box>
          )}

          {/* File Upload Section */}
          <Box
            sx={{
              border: "2px dashed #666",
              borderRadius: 1,
              p: 2,
              bgcolor: "#282c34",
              mb: 2,
            }}
          >
            <Typography
              variant="body2"
              sx={{ mb: 2, textAlign: "center", color: "#888" }}
            >
              Select PDF files for analysis. You can select one or multiple
              files.
            </Typography>

            <input
              accept="application/pdf"
              style={{ display: "none" }}
              id="pdf-upload"
              multiple
              type="file"
              onChange={handleFileSelection}
            />

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <label htmlFor="pdf-upload">
                <Button
                  variant="contained"
                  component="span"
                  startIcon={<UploadFile />}
                  disabled={isLoading}
                >
                  Select PDF Files
                </Button>
              </label>
            </Box>

            {stagedFiles.length > 0 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" sx={{ mb: 1, color: "#fff" }}>
                  Selected files:
                </Typography>
                {stagedFiles.map((file, index) => (
                  <Box
                    key={`${file.name}-${index}`}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      bgcolor: "#3a3a3a",
                      p: 1,
                      mb: 1,
                      borderRadius: 1,
                    }}
                  >
                    <Typography variant="body2" sx={{ flex: 1, color: "#fff" }}>
                      {file.name}
                    </Typography>
                    <Button
                      size="small"
                      onClick={() => {
                        setStagedFiles((prev) =>
                          prev.filter((_, i) => i !== index)
                        );
                      }}
                      sx={{ minWidth: "auto", color: "#888" }}
                    >
                      ×
                    </Button>
                  </Box>
                ))}

                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleUpload}
                  disabled={isLoading}
                  sx={{ mt: 1 }}
                >
                  {isLoading
                    ? "Uploading..."
                    : `Upload ${stagedFiles.length} ${
                        stagedFiles.length === 1 ? "File" : "Files"
                      }`}
                </Button>
              </Box>
            )}
          </Box>

          {/* Document List */}
          {documents.length > 0 && (
            <Box sx={{ bgcolor: "#333", p: 2, borderRadius: 1, mb: 2 }}>
              <Typography variant="body2" sx={{ mb: 1, color: "#888" }}>
                {documents.length === 1
                  ? "Document uploaded."
                  : `${documents.length} documents uploaded. Click to view.`}
              </Typography>
              <Box sx={{ maxHeight: "200px", overflowY: "auto" }}>
                {documents.map((doc) => (
                  <Box
                    key={doc.filename}
                    onClick={() => handleDocumentSelect(doc)}
                    sx={{
                      p: 1.5,
                      mb: 1,
                      bgcolor:
                        selectedDocument?.filename === doc.filename
                          ? "#4a4a4a"
                          : "#3a3a3a",
                      borderRadius: 1,
                      cursor: "pointer",
                      borderLeft:
                        selectedDocument?.filename === doc.filename
                          ? "3px solid #90caf9"
                          : "3px solid transparent",
                    }}
                  >
                    <Typography variant="body2" sx={{ color: "#fff" }}>
                      {doc.filename}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          )}

          {/* PDF Viewer */}
          {selectedDocument &&
            documentViewerUrls[selectedDocument.filename] && (
              <Box sx={{ width: "100%" }}>
                <PdfViewer
                  file={documentViewerUrls[selectedDocument.filename]}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  scale={scale}
                  setScale={setScale}
                  numPages={numPages}
                  setNumPages={setNumPages}
                  onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                  zoomIn={zoomIn}
                  zoomOut={zoomOut}
                />
              </Box>
            )}
        </Box>

        {/* Right Column - Chat Interface */}
        <Box
          className="pdf-takenotes-tab-right"
          sx={{
            padding: {
              xs: "8px 8px 16px 8px",
              md: "15px 20px 0px 0px",
            },
            flex: 1,
            width: { xs: "90%", md: "50%" },  // Add this line to control width
            margin: { xs: "0 auto" },         // Center on mobile
            display: "flex",
            flexDirection: "column",
            position: "relative",
            overflow: "hidden",
            ...(messages.length === 0 && {
              height: {
                xs: "calc(100vh - 180px)",
                md: "100%",
              },
            }),
          }}
        >
          <AdaptiveChatContainer messages={messages}>
            <CustomChatInterface
              messages={messages}
              isTyping={isTyping}
              onSendMessage={handleSendMessage}
              disabled={documents.length === 0}
              selectedDocument={selectedDocument}
              placeholder={
                documents.length === 0
                  ? "Upload documents to start chatting"
                  : "Type question or instruction about the document/s here and press ➤ or Ctrl+ENTER to send."
              }
              sx={{
                height: "100%",
                maxHeight: "100%",
                border: "1px solid #444",
                borderRadius: 1,
                bgcolor: "#282c34",
                display: "flex",
                flexDirection: "column",
              }}
            />
          </AdaptiveChatContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default TakeNotesTab;
